/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video,
button, input {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  outline: none;
  border: none; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

*:focus {
  outline: none; }

a {
  text-decoration: none; }

nav {
  list-style: none; }

ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

@font-face {
  font-family: Monserrat;
  src: url("../fonts/montserrat-regular-webfont.woff2"), url("../fonts/montserrat-regular-webfont.woff"); }

@font-face {
  font-family: Monserrat_medium;
  src: url("../fonts/montserrat-medium-webfont.woff2"), url("../fonts/montserrat-medium-webfont.woff2"); }

@font-face {
  font-family: Monserrat_semibold;
  src: url("../fonts/montserrat-semibold-webfont.woff2"), url("../fonts/montserrat-semibold-webfont.woff"); }

@font-face {
  font-family: Monserrat_bold;
  src: url("../fonts/montserrat-bold-webfont.woff2"), url("../fonts/montserrat-bold-webfont.woff"); }

body {
  box-sizing: border-box; }

.button {
  padding: 12px 28px; }
  .button__container {
    display: flex;
    align-items: center; }
  .button_special {
    margin-left: 90px; }
  .button__span {
    display: flex;
    height: 40px;
    align-items: center;
    padding-left: 5px;
    position: absolute; }
    .button__span_right {
      left: 244px; }
    .button__span_bottom {
      left: 263px;
      height: 46px; }
    .button__span_top {
      left: 135px; }

main {
  height: 100%; }

.main-page__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px 80px;
  align-items: center;
  position: relative; }
  .main-page__header .commit {
    position: absolute;
    left: 360px; }
    .main-page__header .commit__hint {
      top: 33px; }

.main-page__title {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  left: 0; }

.main-page__container {
  display: flex;
  width: 65%;
  align-items: center;
  justify-content: space-between; }

.search__input {
  padding: 12px 16px 12px 50px;
  width: 100%; }
  .search__input-icon {
    position: absolute;
    left: 20px;
    top: 11px; }
    .search__input-icon_x {
      right: 16px;
      left: auto;
      top: 15px; }

.search__label {
  position: relative;
  display: flex; }

.menu {
  padding: 60px;
  display: flex;
  flex-direction: column;
  z-index: 99; }
  .menu__item {
    padding: 0px 20px;
    margin-bottom: 60px; }
    .menu__item:last-child {
      margin-bottom: 0; }
    .menu__item-children {
      padding: 0;
      margin: 8px 0 0; }
    .menu__item-title {
      margin-bottom: 32px; }
    .menu__item-subtitle {
      margin-bottom: 12px; }
  .menu__list {
    display: flex;
    justify-content: space-between;
    flex-direction: row; }
    .menu__list-children {
      display: flex;
      flex-direction: column; }
  .menu-nav {
    width: 416px;
    padding: 20px;
    overflow-y: auto; }
    .menu-nav .menu__list {
      flex-direction: column; }
      .menu-nav .menu__list-item {
        margin-bottom: 10px; }
    .menu-nav .menu__item {
      margin: 20px 0;
      padding: 0; }
      .menu-nav .menu__item:first-child {
        margin-top: 0; }
      .menu-nav .menu__item:last-child {
        margin-bottom: 0; }
      .menu-nav .menu__item-children {
        padding: 10px 20px;
        margin: 0; }
      .menu-nav .menu__item-title {
        margin: 0 0 20px; }
      .menu-nav .menu__item-subtitle {
        margin: 0 0 20px; }
      .menu-nav .menu__item_active {
        padding: 10px 20px; }
    .menu-nav__header {
      margin-bottom: 40px; }
    .menu-nav__title {
      display: flex;
      align-items: center;
      margin-bottom: 20px; }
  .menu-main {
    width: 100%;
    box-sizing: border-box; }
  .menu__empty {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 40px; }
    .menu__empty-main {
      height: calc(100vh / 2);
      align-items: center; }

.container {
  display: flex;
  flex-direction: row; }

.workspace {
  padding: 80px 160px 80px 80px;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .workspace::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none; }
  .workspace__title {
    margin-bottom: 12px; }
  .workspace__subtitle {
    margin-bottom: 40px; }
  .workspace__list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 40px; }
    .workspace__list-title {
      margin-bottom: 20px; }
    .workspace__list-item {
      display: flex; }
  .workspace__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px; }
  .workspace__article {
    margin-top: 60px; }
  .workspace__block-inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%; }

.phone__label {
  padding: 10px;
  width: 396px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative; }

.phone__input-code {
  padding: 16px 24px;
  width: 91px;
  box-sizing: border-box; }

.phone__input-phone {
  width: 100%;
  padding: 16px 24px; }

.phone__input-icon {
  box-sizing: border-box;
  position: absolute;
  margin-left: 50px; }
  .phone__input-icon-wrapper {
    position: absolute;
    right: 11px;
    padding: 16px 20px; }
  .phone__input-icon_danger {
    position: absolute;
    right: 23px;
    top: 23px; }

.navigation {
  margin: 60px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px; }
  .navigation__link {
    width: max-content; }

.modal {
  position: fixed;
  padding: 60px;
  overflow-y: auto;
  max-height: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important; }
  .modal__container {
    padding: 60px;
    overflow-y: auto;
    max-height: 500px;
    margin: 20px auto;
    width: 400px; }
  .modal__close {
    margin-top: 30px; }
  .modal__button-group {
    display: flex;
    justify-content: space-between;
    column-gap: 2vw;
    margin-top: 40px; }
    .modal__button-group .button {
      width: 190px;
      justify-content: center; }
  .modal__title_align {
    text-align: center; }
  .modal__text {
    margin: 20px 0; }
    .modal__text_align {
      text-align: center; }
  .modal-page__image {
    width: max-content;
    margin: 0 auto; }
    .modal-page__image-wrapper {
      display: flex;
      justify-content: center;
      column-gap: 32px; }
  .modal-page__list {
    display: flex;
    flex-direction: column;
    padding-left: 60px;
    row-gap: 10px; }
  .modal-page__text {
    column-gap: 20px;
    display: flex;
    margin: 40px 0; }

.input {
  width: 376px; }
  .input__wrapper {
    display: flex;
    flex-direction: column; }
  .input__container {
    display: flex;
    flex-direction: row; }
  .input__span {
    position: absolute; }
    .input__span_top {
      height: 12px;
      vertical-align: middle;
      left: 70px; }
    .input__span_left {
      top: 58px;
      width: 16px;
      text-align: center;
      left: 11px; }
    .input__span_title {
      top: 27px;
      height: 8px;
      left: 30px; }

.error {
  margin-top: 12px; }
  .error__phone {
    position: absolute;
    bottom: -30px;
    left: 0; }

.warning {
  margin-top: 12px; }

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-width: 200px;
  min-height: 55px; }
  .select__container {
    position: relative; }
  .select__icon {
    margin-right: -5px; }
  .select__list {
    position: absolute;
    top: 40px;
    z-index: 20;
    left: 0;
    width: 100%;
    margin: 22px 0; }
  .select__item {
    padding: 12px 24px; }
  .select__choice {
    position: relative;
    margin: 12px 0 0 0;
    padding: 12px 16px;
    min-height: 46px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 376px; }
    .select__choice_full-width {
      width: auto; }
    .select__choice-icon {
      z-index: 10;
      position: absolute;
      right: 1vw; }
    .select__choice-dropdown {
      position: absolute;
      z-index: 20;
      left: 0;
      width: 100%;
      margin: 8px 0 0;
      top: 100%;
      max-height: 160px;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none; }
      .select__choice-dropdown::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none; }
      .select__choice-dropdown_active {
        margin: 40px 0 0; }
    .select__choice-list {
      display: inline-flex;
      flex-wrap: wrap;
      gap: 12px;
      max-width: 260px;
      align-items: center; }
      .select__choice-list_full-width {
        max-width: 17vw; }
    .select__choice-item {
      padding: 5px 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 0.3vw; }
      .select__choice-item_active {
        max-width: 7.5vw;
        overflow-x: hidden;
        text-overflow: ellipsis; }
    .select__choice-option {
      padding: 12px 16px; }
    .select__choice-input {
      display: inline-block;
      top: 0px;
      height: 100%;
      z-index: 10;
      max-width: 100px; }
      .select__choice-input_full-width {
        max-width: max-content; }
  .select__span_top {
    position: absolute;
    top: 27px;
    left: 48px;
    height: 9px; }
  .select__span_number {
    position: absolute;
    left: 28px;
    top: 40px;
    width: 8px; }
  .select__plan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: Max(200px, 10vw);
    padding: 15px; }
    .select__plan-container {
      position: relative;
      margin: 12px 0 0; }
    .select__plan-icon {
      margin-right: -5px; }
    .select__plan-list {
      position: absolute;
      top: 50px;
      z-index: 20;
      left: 0;
      width: 100%;
      margin: 22px 0; }
      .select__plan-list-item {
        padding: 3; }
    .select__plan-item {
      padding: 12px 24px; }
  .select__image {
    width: max-content;
    margin: 32px 0; }
  .select__page-list {
    display: flex;
    column-gap: 12px;
    margin-bottom: 20px; }
    .select__page-list_margin {
      margin-bottom: 170px; }

.password__label {
  position: relative;
  width: max-content;
  display: block; }

.password__text {
  position: absolute;
  right: 24px;
  height: 20px;
  bottom: 13px; }

.password__warning {
  margin: 0;
  position: relative;
  top: -6px;
  margin-left: 90px; }

.password__error {
  margin-left: 90px; }

.password__input {
  padding: 12px 16px;
  margin: 0;
  width: 376px; }

.label {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 20px 0;
  padding: 10px;
  position: relative; }
  .label_inline {
    display: flex;
    flex-direction: row;
    column-gap: 1vw;
    align-items: center; }
  .label_focused {
    margin-bottom: 125px;
    margin-top: -45px; }
  .label_typing {
    margin-bottom: 85px; }
  .label_filled {
    margin-top: 35px; }
  .label_selected {
    margin-top: 0;
    margin-bottom: 175px; }
  .label_without-margin {
    margin: 0; }
  .label_without-padding {
    padding: 0; }
  .label_last {
    padding-top: 0;
    margin-bottom: 140px; }
  .label__text_colored {
    width: max-content; }
  .label__text_inline {
    width: 72px; }
  .label__text_nowrap {
    width: max-content;
    white-space: nowrap; }
  .label__container {
    width: max-content; }
  .label__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 10px;
    width: max-content; }

.meatball__article {
  margin-top: 60px; }

.meatball__image {
  margin: 32px auto; }
  .meatball__image-container {
    display: flex;
    justify-content: center;
    column-gap: 32px; }

.tabbar__image {
  margin: 0 auto; }
  .tabbar__image_medium {
    margin: 0 auto; }
  .tabbar__image_small {
    margin: 0 auto; }
  .tabbar__image_large {
    margin: 0 auto; }
  .tabbar__image-container {
    display: flex;
    justify-content: center;
    column-gap: 32px; }

.tabs__list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  margin: 0;
  width: 100%; }
  .tabs__list-item {
    padding: 10px 2vw;
    margin: 0 10px -11px;
    width: 50%;
    text-align: center; }
    .tabs__list-item_large {
      width: 373px; }

.tabs__label {
  flex-direction: row;
  align-items: center; }
  .tabs__label-text {
    white-space: nowrap;
    width: 12vw; }

.tabs__image {
  margin: 20px auto; }

.tabs__wrapper {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  margin: 20px 0; }
  .tabs__wrapper-item {
    row-gap: 32px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
  .tabs__wrapper-text {
    text-align: center; }

.table {
  width: 100%;
  margin-top: 60px; }
  .table__cell {
    padding: 20px; }
    .table__cell:first-child {
      padding-left: 104px; }
  .table__fillets {
    margin-top: -20px; }
  .table__colors {
    width: 70%; }
    .table__colors .color__square {
      flex-direction: row;
      column-gap: 20px;
      justify-content: center;
      align-items: center; }
    .table__colors .color__content {
      width: 166px;
      padding: 19px 0;
      max-width: auto; }
    .table__colors .color__background {
      width: 40px;
      height: 40px; }
    .table__colors .table__cell {
      text-align: center; }

.color__list {
  display: flex;
  margin-bottom: 32px;
  column-gap: 12px; }

.color__background {
  margin-bottom: 3px;
  box-sizing: border-box; }

.color__square {
  display: flex;
  flex-direction: column;
  align-items: center; }

.color__table_small {
  width: 205px; }

.color__table_margin {
  margin-bottom: 32px; }

.color__table-item {
  padding: 4px 10px 0 10px; }
  .color__table-item:first-child {
    padding-left: 0; }
  .color__table-item:last-child {
    padding-right: 0; }

.color__wrapper {
  column-gap: 60px;
  display: flex;
  width: 100%;
  margin-bottom: 32px; }

.color__content {
  max-width: 67px; }

.layout__grid {
  display: flex;
  width: 870px;
  column-gap: 30px;
  margin-bottom: 40px;
  position: relative; }
  .layout__grid::before {
    width: 80px;
    height: 80px;
    content: ''; }
  .layout__grid::after {
    width: 80px;
    height: 80px;
    content: ''; }
  .layout__grid_tablet {
    width: 356px;
    column-gap: 20px; }
    .layout__grid_tablet::before {
      width: 28px; }
    .layout__grid_tablet::after {
      width: 28px; }
  .layout__grid_mobile {
    width: 76px;
    column-gap: 8px; }
    .layout__grid_mobile::before {
      width: 16px; }
    .layout__grid_mobile::after {
      width: 16px; }

.layout__column {
  width: 28px;
  height: 80px; }
  .layout__column_tablet {
    width: 20px; }
  .layout__column_mobile {
    width: 17px; }

.layout__size {
  position: absolute;
  top: 40px;
  padding-top: 4px; }
  .layout__size-left_web {
    width: 77px; }
  .layout__size-left_tablet {
    width: 23px; }
  .layout__size-left_mobile {
    width: 12px; }
  .layout__size-right_web {
    width: 77px;
    right: 0; }
  .layout__size-right_tablet {
    width: 23px;
    right: 0; }
  .layout__size-right_mobile {
    width: 12px;
    right: 0; }
  .layout__size-center_web {
    width: 30px;
    left: 420px; }
  .layout__size-center_tablet {
    width: 20px;
    left: 168px; }
  .layout__size-center_mobile {
    width: 8px;
    left: 34px; }

.text__wrapper {
  display: flex;
  width: 100%; }

.text__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 40px;
  width: 778px;
  padding: 0 40px 40px; }
  .text__list:first-child {
    padding-left: 0; }
  .text__list:last-child {
    padding-right: 0; }
  .text__list-item {
    max-width: 350px; }
    .text__list-item_border {
      padding-top: 40px; }

.text__info {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin-bottom: 20px; }
  .text__info-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.text__use {
  margin-top: 32px; }

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 10px 0; }
  .pagination__block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto; }
  .pagination__number {
    padding: 4px 12px; }
  .pagination__list {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin: 0 20px; }
  .pagination__wrapper {
    display: flex;
    flex-direction: column;
    padding: 80px;
    margin: 32px auto;
    width: 631px;
    box-sizing: border-box;
    position: relative; }
  .pagination__article {
    margin-top: 60px; }

.checkbox__container {
  display: flex;
  align-items: center;
  justify-items: center;
  position: relative; }
  .checkbox__container .checkbox__item:checked ~ .checkbox__checkmark::after {
    display: block; }
  .checkbox__container .checkbox__checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px; }

.checkbox__item {
  position: absolute;
  height: 0;
  width: 0; }

.checkbox__checkmark {
  position: absolute;
  height: 25px;
  width: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .checkbox__checkmark::after {
    content: "";
    position: absolute;
    display: none; }

.checkbox__image {
  width: max-content;
  margin: 32px 0; }

.checkbox__table-content {
  display: flex;
  column-gap: 32px;
  padding: 20px 40px; }

.checkbox__table-text {
  margin-top: 11px; }

.calendar__image {
  width: max-content;
  margin: 32px 0; }
  .calendar__image-wrapper {
    display: flex;
    justify-content: flex-start; }

.calendar__table {
  margin-bottom: 32px; }
  .calendar__table-content {
    display: flex;
    column-gap: 32px;
    padding: 20px 40px; }
  .calendar__table-text {
    margin-top: 11px; }

.calendar__article {
  margin-top: 60px; }

.hint__image {
  width: max-content;
  margin: 32px 0; }

.hint__article {
  margin-top: 60px; }

.usage__image {
  width: max-content;
  margin: 0 auto; }
  .usage__image-wrapper {
    display: flex;
    justify-content: center;
    column-gap: 32px; }

.validation__image {
  width: max-content;
  margin: 0 auto; }

.validation__wrapper .password__label {
  display: flex;
  flex-direction: column; }

.validation__wrapper .password__error {
  margin-left: 0;
  margin-top: 10px; }

.validation__wrapper .password__input {
  margin-top: 10px; }

.validation__article {
  margin-top: 60px; }

.validation__list {
  display: flex;
  flex-direction: column;
  row-gap: 32px; }

.library__link {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: flex-end;
  margin-top: 60px; }

.logo__table {
  margin-top: 60px;
  width: 100%; }
  .logo__table-icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 40px;
    padding: 16px; }
  .logo__table-text {
    display: inline-block;
    vertical-align: middle; }

.logo__list {
  margin: 60px 0;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  column-gap: 60px;
  row-gap: 32px; }
  .logo__list-item {
    display: flex;
    height: 140px;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 32px; }

.icons__list {
  display: flex;
  flex-wrap: wrap;
  max-width: 1015px;
  width: 100%;
  margin-top: 60px;
  margin-left: -20px; }

.icons__item {
  padding: 20px; }

.icons__calendar-icon {
  padding: 15px; }

.icons__calendar-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 71px;
  height: 56px; }

.icons__calendar-list {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  column-gap: 40px; }

.icons__calendar-row {
  display: flex;
  flex-direction: row;
  row-gap: 20px;
  column-gap: 40px; }

.icons__calendar-article {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 60px;
  margin-bottom: 60px;
  column-gap: 40px; }

.icons__plan-list {
  column-gap: 40px;
  display: flex; }

.icons__loading-list {
  display: flex;
  column-gap: 32px; }

.loading {
  display: flex;
  column-gap: 0.5vw;
  margin: 12px 0 32px;
  height: 100px;
  justify-content: center;
  align-items: center; }
  .loading__item {
    width: 8px;
    height: 40px; }

.commit__hint {
  position: absolute;
  top: 60px;
  transform: translateX(-10px);
  left: auto;
  padding: 4px 12px; }

.header__block {
  display: flex;
  column-gap: 40px;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap; }
  .header__block_margin {
    margin-bottom: 60px; }
  .header__block_last {
    align-items: flex-start; }

.header__images {
  display: flex;
  flex-direction: column; }
  .header__images :first-child {
    margin-bottom: -27px;
    margin-top: -27px; }

.scroll {
  scrollbar-color: #C4C4C4 initial;
  scrollbar-width: thick; }
  .scroll::-webkit-scrollbar {
    width: 24px;
    height: 32px; }
  .scroll::-webkit-scrollbar-track {
    background: initial; }
  .scroll::-webkit-scrollbar-thumb {
    background: #C4C4C4;
    border: 8px solid white;
    background-clip: padding-box;
    border-radius: 9999px; }

.main__title {
  font-size: 40px;
  line-height: 48px;
  font-family: 'Monserrat_semibold', sans-serif;
  color: #141615; }

.main__subtitle {
  font-size: 20px;
  line-height: 26px;
  font-family: 'Monserrat_medium', sans-serif;
  color: #141615; }

.block__title-h2 {
  font-size: 34px;
  line-height: 42px;
  font-family: 'Monserrat_semibold', sans-serif;
  color: #141615; }

.block__title-h3 {
  font-size: 28px;
  line-height: 36px;
  font-family: 'Monserrat_semibold', sans-serif;
  color: #141615; }

.block__subtitle {
  font-size: 24px;
  line-height: 30px;
  font-family: 'Monserrat_semibold', sans-serif;
  color: #141615; }

.block__content {
  font-size: 16px;
  line-height: 22px;
  font-family: 'Monserrat_medium', sans-serif;
  color: #141615; }

.block__table {
  font-size: 16px;
  line-height: 22px;
  font-family: 'Monserrat_medium', sans-serif;
  color: #141615; }

.block__button {
  font-size: 16px;
  line-height: 22px;
  font-family: 'Monserrat_medium', sans-serif;
  color: #141615; }
  .block__button_small {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Monserrat', sans-serif;
    color: #141615; }

.block__text {
  font-size: 14px;
  line-height: 20px;
  font-family: 'Monserrat', sans-serif;
  color: #141615; }

.block__bullet {
  font-size: 12px;
  line-height: 18px;
  font-family: 'Monserrat', sans-serif;
  color: #141615; }

body {
  font-family: 'Monserrat', sans-serif;
  background-color: #FAFAFA;
  color: #24312D; }

.main-page__header {
  background-color: white; }

.main-page__title {
  color: #264948;
  white-space: nowrap; }

.search__input {
  border-radius: 12px;
  border: 1px solid #C4C4C4; }
  .search__input::placeholder {
    color: #D2D2D2; }
  .search__input-icon {
    width: 20px;
    min-height: 20px;
    fill: #C4C4C4; }
    .search__input-icon_x {
      width: 16px;
      min-height: 16px;
      fill: #24312D;
      transform: rotate(45deg); }

.menu {
  color: #24312D;
  background-color: transparent; }
  .menu__logo {
    width: 268px;
    height: 62px; }
    .menu__logo_small {
      width: 44px;
      min-height: 44px; }
  .menu__item {
    cursor: pointer; }
    .menu__item-title {
      cursor: pointer; }
      .menu__item-title:hover {
        color: #397271; }
    .menu__item-subtitle {
      font-family: 'Monserrat_semibold', sans-serif;
      cursor: pointer; }
      .menu__item-subtitle:hover {
        color: #397271; }
    .menu__item-children {
      cursor: pointer;
      color: #264948; }
      .menu__item-children:hover {
        color: #397271; }
      .menu__item-children_disabled {
        color: #767676; }
        .menu__item-children_disabled:hover {
          color: #767676; }
  .menu__header {
    display: flex;
    flex-direction: column; }
  .menu-nav {
    background-color: #F8F2EF;
    overflow-y: auto;
    scrollbar-width: none; }
    .menu-nav::-webkit-scrollbar {
      display: none; }
    .menu-nav .menu__item-title {
      font-size: 20px;
      line-height: 26px; }
    .menu-nav .menu__item-subtitle {
      font-size: 16px;
      line-height: 22px; }
    .menu-nav .menu__item_active {
      background-color: #264948;
      color: white;
      border-radius: 12px; }
    .menu-nav__title {
      font-family: 'Monserrat_semibold', sans-serif;
      cursor: pointer; }
      .menu-nav__title:hover {
        color: #397271; }
  .menu__empty {
    color: #767676;
    text-align: center; }

.workspace__title {
  font-family: 'Monserrat_medium', sans-serif; }

.workspace__subtitle {
  font-family: 'Monserrat_medium', sans-serif; }

.workspace__last-update {
  color: #C4C4C4; }

.workspace__list-item {
  counter-increment: list-couter; }
  .workspace__list-item::before {
    content: counter(list-couter, decimal-leading-zero);
    font-size: 12px;
    line-height: 18px;
    color: #D2D2D2;
    display: block;
    margin-right: 20px; }

.workspace__list-link {
  color: #24312D;
  border-bottom: 1px solid #24312D;
  font-family: 'Monserrat', sans-serif; }

.workspace__image {
  width: 390px; }
  .workspace__image_small {
    width: max-content; }

.navigation__link {
  color: #264948;
  border-bottom: 1px solid #264948;
  cursor: pointer; }
  .navigation__link_small {
    border-bottom: 1px solid #264948;
    cursor: pointer;
    color: #264948; }

.phone__label {
  border-radius: 12px;
  background-color: white; }

.phone__input-phone {
  border: 1px solid #C4C4C4;
  background-color: white;
  border-radius: 12px; }
  .phone__input-phone::placeholder {
    color: #D2D2D2; }

.phone__input-code {
  background-color: white;
  color: #24312D; }

.phone__input-icon {
  width: 16px;
  min-height: 16px;
  stroke: #C4C4C4;
  transform: rotate(180deg); }
  .phone__input-icon_x {
    width: 16px;
    min-height: 16px;
    fill: #24312D;
    transform: rotate(45deg); }
  .phone__input-icon-wrapper {
    border-radius: 12px;
    background-color: #E12E2E1A; }
  .phone__input-icon_danger {
    width: 24px;
    min-height: 24px;
    fill: none; }

.phone__input_error {
  border: 1px solid #E72323; }

.label__text_colored {
  background-color: #ECEAEA; }

.label__text_inline {
  font-family: 'Monserrat_medium', sans-serif; }
  .label__text_inline::first-letter {
    text-transform: uppercase; }

.input {
  border: 1px solid #C4C4C4;
  border-radius: 12px;
  padding: 12px 16px; }
  .input::placeholder {
    color: #D2D2D2; }
  .input__span {
    color: #E72323; }
    .input__span_top {
      border-left: 1px solid #E72323;
      line-height: 12px; }
    .input__span_left {
      border-top: 1px solid #E72323; }
    .input__span_title {
      border-left: 1px solid #E72323;
      line-height: 8px; }
  .input_colored {
    background-clip: content-box;
    background-color: #D2D2D2; }
  .input_error {
    border: 1px solid #E72323; }
  .input_warning {
    border: 1px solid #F88545; }

.error {
  color: #E72323; }

.warning {
  color: #F88545; }

.modal {
  border-radius: 28px;
  background-color: white;
  z-index: 201; }
  .modal__container {
    background-color: white;
    border-radius: 28px; }
  .modal__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.3;
    z-index: 200; }
  .modal::-webkit-scrollbar {
    display: none; }
  .modal__close {
    text-align: right;
    color: #24312D;
    font-family: 'Monserrat_semibold', sans-serif; }
  .modal__title {
    font-family: 'Monserrat_medium', sans-serif; }
  .modal__text {
    color: #C4C4C4;
    font-family: 'Monserrat', sans-serif; }
  .modal-page__icon {
    width: 22px;
    min-height: 22px; }
    .modal-page__icon-x {
      fill: #E72323;
      transform: rotate(45deg); }
    .modal-page__icon-check {
      fill: #397271; }
  .modal-page__image_small {
    width: 360px; }
  .modal-page__list {
    list-style: disc; }
  .modal-page__text {
    font-family: 'Monserrat', sans-serif; }

.button {
  background-color: #FFBCB8;
  color: #264948;
  border-radius: 12px;
  height: max-content;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .button__content:first-letter {
    text-transform: uppercase; }
  .button_disabled {
    background-color: #ECEAEA;
    color: #C4C4C4; }
    .button_disabled .button__icon-filter {
      fill: #C4C4C4; }
    .button_disabled .button__icon-plus {
      fill: #C4C4C4; }
    .button_disabled-ghost {
      color: #C4C4C4;
      background-color: initial; }
      .button_disabled-ghost .button__icon {
        fill: #C4C4C4; }
  .button_outline {
    background-color: white;
    border: 2px solid #FFBCB8; }
    .button_outline-thin {
      border: 1px solid #FFBCB8;
      background-color: white; }
    .button_outline-none {
      border: none;
      background-color: initial; }
    .button_outline-secondary {
      background-color: white;
      border: 1px solid #397271;
      color: #397271; }
  .button_hovered {
    background-color: #FFE4E0; }
    .button_hovered-secondary {
      background-color: #397271;
      color: white; }
      .button_hovered-secondary .button__icon-filter {
        fill: white; }
      .button_hovered-secondary .button__icon-plus {
        fill: #24312D; }
    .button_hovered-ghost {
      background-color: initial;
      color: #397271; }
      .button_hovered-ghost .button__icon {
        fill: #397271; }
    .button_hovered-ouline {
      background-color: #FFE4E0; }
  .button_pressed {
    color: white;
    background-color: #CC908C; }
    .button_pressed .button__icon {
      fill: black; }
    .button_pressed .button__icon-plus {
      fill: white; }
    .button_pressed-ghost {
      color: black;
      background-color: initial; }
    .button_pressed-secondary {
      background-color: #264948;
      color: white; }
      .button_pressed-secondary .button__icon-filter {
        fill: white; }
  .button_small {
    height: 40px; }
  .button_medium {
    width: max-content;
    justify-content: center;
    height: 46px; }
  .button_large {
    width: max-content;
    height: 46px; }
  .button_ghost {
    background-color: initial;
    border: none;
    color: #264948; }
  .button__icon {
    width: 24px;
    min-height: 24px;
    fill: #264948;
    margin-right: 1vw; }
    .button__icon-filter {
      width: 24px;
      min-height: 24px;
      fill: #397271; }
    .button__icon-x {
      margin-right: 0;
      margin-left: 1vw;
      order: 3; }
    .button__icon-plus {
      width: 14px;
      min-height: 14px;
      margin-right: 0;
      order: 3;
      margin-left: 1vw; }
  .button__text {
    order: 2; }
  .button__span {
    border-left: 1px solid #E72323;
    color: #E72323;
    font-size: 12px;
    white-space: nowrap; }

.select {
  background-color: white;
  cursor: pointer; }
  .select__icon {
    width: 15px;
    stroke: #C4C4C4;
    transform: rotate(180deg); }
    .select__icon_active {
      transform: rotate(0deg); }
  .select__list {
    z-index: 20;
    background: white;
    border: 1px solid #C4C4C4;
    border-radius: 12px; }
  .select__item {
    cursor: pointer;
    border-radius: 5px;
    list-style-type: none; }
    .select__item:hover {
      color: #397271; }
    .select__item-text {
      color: #24312D;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow-x: hidden; }
  .select__choice {
    border-radius: 12px;
    border: 1px solid #C4C4C4;
    color: #24312D;
    background-color: white; }
    .select__choice_full-width {
      border-radius: 12px; }
    .select__choice_disabled {
      background-color: #ECEAEA;
      border: 1px solid #C4C4C4; }
    .select__choice_hover-variant .select__choice-option:first-child {
      color: #397271; }
    .select__choice-icon {
      width: 20px;
      min-height: 20px;
      stroke: #24312D;
      transform: rotate(180deg);
      cursor: pointer; }
      .select__choice-icon_active {
        transform: rotate(0deg); }
      .select__choice-icon_full-width {
        width: 15px; }
    .select__choice-dropdown {
      background-color: white;
      border-radius: 12px;
      border: 1px solid #C4C4C4; }
    .select__choice-item {
      border-radius: 12px;
      background-color: #FFE4E0;
      cursor: pointer; }
      .select__choice-item-icon {
        fill: #24312D;
        width: 12px;
        height: 12px; }
    .select__choice-option {
      font-family: 'Monserrat', sans-serif;
      cursor: pointer; }
      .select__choice-option:hover {
        color: #397271; }
    .select__choice-text {
      white-space: nowrap; }
    .select__choice-placeholder {
      color: #D2D2D2; }
    .select__choice-input::placeholder {
      color: #D2D2D2; }
    .select__choice-input_disabled {
      background-color: #ECEAEA; }
  .select__span {
    color: #E72323; }
    .select__span_top {
      border-left: 1px solid #E72323;
      line-height: 7px; }
    .select__span_number {
      border-top: 1px solid #E72323; }
  .select__plan {
    background-color: white;
    cursor: pointer;
    border: 1px solid #FFBCB8;
    border-radius: 12px;
    font-family: 'Monserrat_medium', sans-serif; }
    .select__plan_active {
      background-color: #FFE4E0;
      border: 1px solid #FFE4E0; }
    .select__plan_hovered {
      background-color: #FFF1EF; }
    .select__plan_hover-variant {
      background-color: #FFE4E0;
      border-color: #FFE4E0; }
    .select__plan_disabled {
      background-color: #ECEAEA;
      border: 1px solid #C4C4C4; }
    .select__plan-icon {
      width: 24px;
      min-height: 24px;
      stroke: #264948;
      transform: rotate(180deg); }
      .select__plan-icon_active {
        transform: rotate(0deg); }
    .select__plan-list {
      z-index: 20;
      background: white;
      border: 1px solid #C4C4C4;
      border-radius: 12px; }
      .select__plan-list_hover-variant .select__plan-item:first-child {
        color: #397271; }
    .select__plan-item {
      cursor: pointer; }
      .select__plan-item:hover {
        color: #397271; }
      .select__plan-item-text {
        font-family: 'Monserrat', sans-serif;
        color: #264948; }

.password__input {
  border-radius: 12px;
  border: 1px solid #C4C4C4;
  font-family: 'Monserrat', sans-serif; }
  .password__input_error {
    border-color: #E72323; }
  .password__input_warning {
    border-color: #F88545; }

.password__text {
  cursor: pointer;
  text-transform: capitalize; }

.password__warning {
  color: #F88545; }

.password__error {
  color: #E72323; }

.meatball__image_small {
  width: 281px;
  height: 552px;
  border-radius: 13.5px; }

.tabs__list {
  border-bottom: 1px solid #C4C4C4; }
  .tabs__list-item {
    color: #C4C4C4;
    cursor: pointer; }
    .tabs__list-item_active {
      border-bottom: 2px solid #FFBCB8;
      color: #24312D; }

.table {
  text-align: left; }
  .table__header {
    background-color: #F8F2EF;
    color: #24312D;
    font-family: 'Monserrat_medium', sans-serif; }
    .table__header :first-child {
      border-top-left-radius: 16px; }
    .table__header :last-child {
      border-top-right-radius: 16px; }
  .table__body {
    background-color: white;
    font-family: 'Monserrat', sans-serif;
    color: #24312D; }
  .table__row {
    border-bottom: 1px solid #ECEAEA; }
    .table__row:last-child {
      border-bottom: none; }
      .table__row:last-child .table__cell {
        border-bottom: 1px solid #ECEAEA; }
    .table__row:last-child .table__cell:first-child {
      border-bottom-left-radius: 16px; }
    .table__row:last-child .table__cell:last-child {
      border-bottom-right-radius: 16px; }
  .table__colors td:not(:first-child) {
    background-color: #767676; }
    .table__colors td:not(:first-child) .color__content {
      color: white; }
  .table__colors .color__content {
    font-size: 16px; }

.color__background {
  width: 60px;
  height: 60px;
  border-radius: 12px; }

.color__content {
  color: #141615;
  text-align: center; }

.color__table-logo {
  width: 40px;
  height: 40px; }

.layout__grid {
  background-color: white; }
  .layout__grid::before {
    background-color: #FFBCB8; }
  .layout__grid::after {
    background-color: #FFBCB8; }

.layout__column {
  background-color: #FFE4E0; }

.layout__size {
  color: #E72323;
  text-align: center;
  border-top: 1px solid #E72323; }

.text__info {
  color: #767676;
  font-size: 12px; }
  .text__info-name {
    width: max-content;
    color: #24312D;
    background-color: #E0EBEB;
    border-radius: 8px;
    padding: 12px;
    font-size: 22px;
    text-align: center;
    vertical-align: middle;
    font-family: 'Monserrat_semibold', sans-serif;
    box-sizing: border-box; }

.text__use {
  color: #767676; }

.text__title-m {
  font-size: 15px;
  line-height: 20px;
  font-family: 'Monserrat_medium', sans-serif; }

.text__hr {
  border: 1px solid #C4C4C4;
  width: 100%; }

.text__list-item {
  color: #24312D; }
  .text__list-item_border {
    border-top: 1px solid #C4C4C4;
    width: 100%; }

.pagination {
  color: #264948; }
  .pagination__number {
    cursor: pointer;
    font-family: 'Monserrat_medium', sans-serif; }
    .pagination__number_active {
      color: white;
      background-color: #264948;
      border-radius: 8px; }
  .pagination__item {
    cursor: pointer; }
  .pagination__icon {
    width: 20px;
    min-height: 20px;
    stroke: #264948;
    transform: rotate(270deg); }
    .pagination__icon_right {
      transform: rotate(90deg); }
  .pagination__wrapper {
    background-color: white;
    border-radius: 28px; }
  .pagination__images {
    display: flex;
    align-items: flex-start; }

.checkbox__container {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .checkbox__container:hover .checkbox__item ~ .checkbox__checkmark {
    background-color: white; }
  .checkbox__container .checkbox__item:checked ~ .checkbox__checkmark {
    background-color: #264948;
    border: none; }
  .checkbox__container .checkbox__checkmark:after {
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .checkbox__container_hovered .checkbox__checkmark {
    background-color: #26494899; }
  .checkbox__container_hovered:hover .checkbox__item ~ .checkbox__checkmark {
    background-color: #26494899; }
  .checkbox__container_disabled .checkbox__checkmark {
    background-color: #ECEAEA;
    border-color: #C4C4C4; }
  .checkbox__container_disabled:hover .checkbox__item ~ .checkbox__checkmark {
    background-color: #ECEAEA;
    border-color: #C4C4C4; }

.checkbox__item {
  opacity: 0;
  cursor: pointer; }

.checkbox__checkmark {
  border: 1px solid #264948;
  border-radius: 4px;
  background-color: white; }

.checkbox__table-text {
  font-family: 'Monserrat', sans-serif; }
  .checkbox__table-text_disabled {
    color: #C4C4C4; }

.calendar__table-content_base {
  padding: 8px; }

.calendar__table-content_hover {
  color: #CC908C;
  padding: 8px; }

.calendar__table-content_pressed {
  border-radius: 8px;
  background-color: #FFE4E0;
  padding: 8px; }

.calendar__table-content_disabled {
  color: #C4C4C4;
  padding: 8px; }

.usage__image_small {
  width: 360px; }

.library__link {
  color: #24312D; }
  .library__link-item {
    color: #24312D;
    border-bottom: 1px solid #24312D; }

.logo__table {
  font-family: 'Monserrat', sans-serif; }
  .logo__table-header {
    text-align: left; }
  .logo__table-item {
    text-align: left; }

.logo__with-text_full-width {
  width: 150px;
  height: 33px; }

.logo__with-text_mobile {
  width: 115px;
  height: 26px; }

.logo__menu_full-width {
  width: 120px;
  height: 120px; }

.logo__menu_mobile {
  width: 80px;
  height: 80px; }

.logo__menu-small_full-width {
  width: 44px;
  height: 44px; }

.logo__menu-small_mobile {
  width: 44px;
  height: 44px; }

.icons__item {
  width: 24px;
  min-height: 24px;
  fill: #264948; }
  .icons__item-arrow {
    width: 26px;
    min-height: 26px; }
  .icons__item-burger {
    width: 32px;
    min-height: 32px;
    fill: none;
    stroke: #264948; }
  .icons__item_small {
    fill: none;
    stroke: #264948; }
  .icons__item_big {
    width: 68px;
    min-height: 68px; }
  .icons__item-chevron {
    fill: none;
    stroke: #264948;
    transform: rotate(90deg);
    width: 10px; }
  .icons__item-down {
    fill: none;
    stroke: #264948;
    transform: rotate(180deg); }
  .icons__item-check {
    fill: white; }
  .icons__item-cone {
    stroke: #264948;
    stroke-linecap: round;
    transform: rotate(180deg); }
  .icons__item-repeat {
    width: 14px;
    min-height: 14px; }
  .icons__item-x {
    width: 16px;
    min-height: 16px; }

.icons__calendar-icon {
  border: 1px solid #FFBCB8;
  border-radius: 12px;
  width: 24px;
  min-height: 24px;
  box-sizing: content-box;
  fill: #264948; }
  .icons__calendar-icon_hover {
    background-color: #FFF1EF; }
  .icons__calendar-icon_pressed {
    background-color: #FFE4E0;
    border: 1px solid #FFE4E0; }
  .icons__calendar-icon_disabled {
    background-color: #ECEAEA;
    border: 1px solid #ECEAEA;
    fill: #C4C4C4; }

.icons__calendar-text {
  border: 1px solid #FFBCB8;
  border-radius: 12px;
  font-size: 16px; }
  .icons__calendar-text_hover {
    background-color: #FFF1EF; }
  .icons__calendar-text_pressed {
    background-color: #FFE4E0;
    border: 1px solid #FFE4E0; }
  .icons__calendar-text_disabled {
    background-color: #ECEAEA;
    border: 1px solid #ECEAEA;
    color: #C4C4C4; }

.icons__calendar-number {
  font-family: 'Monserrat_semibold', sans-serif; }

.icons__loading-info {
  font-size: 20px; }

.loading__item {
  border-radius: 12px;
  background-color: #FFBCB8;
  opacity: 0.65; }
  .loading__item_active {
    height: 70px;
    background-color: #FFBCB8;
    opacity: 1; }

.commit__icon {
  width: 24px;
  height: 24px;
  fill: none; }

.commit__hint {
  background-color: #141615;
  border-radius: 8px;
  z-index: 10;
  font-size: 14px;
  line-height: 20px;
  color: white;
  width: max-content;
  display: block; }
  .commit__hint-cone {
    display: block;
    position: absolute;
    width: 15px;
    fill: #141615;
    stroke: #141615;
    transform: rotate(0deg);
    bottom: 46px;
    left: 14px; }

@media (min-width: 768px) and (max-width: 1024px) {
  body {
    min-width: 768px; }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 16px 12px;
    align-items: center;
    background-color: white; }
    .header__logo {
      width: 44px;
      height: 44px; }
    .header__menu {
      width: 25px;
      height: 30px;
      stroke: #264948; }
  .workspace {
    width: 100%;
    padding: 28px; }
  .menu {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 120;
    width: 262px; }
    .menu__wrapper {
      width: 77%;
      right: 0;
      top: 0;
      bottom: 0;
      position: fixed;
      background-color: #FAFAFA;
      opacity: 0.9;
      z-index: 120; }
    .menu__logo {
      margin: 0;
      min-width: 80px;
      max-width: 80px;
      height: 80px; }
    .menu-main {
      position: relative;
      width: 100%; }
      .menu-main .menu__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 40px; }
  .main-page__title {
    position: relative; }
  .main-page__header .commit {
    left: 150px;
    top: 40px; }
  .button {
    justify-content: center; }
    .button_medium {
      width: 250px; }
    .button_outline-thin {
      width: 212px; }
    .button_outline-none {
      width: 254px; }
    .button_outline-secondary {
      width: 156px;
      height: 46px; }
    .button__span {
      left: 303px; }
      .button__span_bottom {
        left: 412px; }
      .button__span_right {
        left: 563px; }
    .button_hovered:first-child {
      width: 376px; }
    .button_hovered:nth-child(2) {
      width: 257px; }
    .button_hovered:nth-child(3) {
      width: 212px; }
    .button_hovered:nth-child(4) {
      width: 254px; }
    .button_hovered:nth-child(5) {
      width: 202px; }
    .button_hovered-secondary {
      width: 156px;
      height: 46px; }
    .button_pressed:first-child {
      width: 376px; }
    .button_pressed:nth-child(2) {
      width: 257px; }
    .button_pressed:nth-child(3) {
      width: 212px; }
    .button_pressed:nth-child(4) {
      width: 254px; }
    .button_pressed:nth-child(5) {
      width: 202px; }
    .button_pressed-secondary {
      width: 156px;
      height: 46px; }
    .button_disabled:first-child {
      width: 376px; }
    .button_disabled:nth-child(2) {
      width: 257px; }
    .button_disabled:nth-child(3) {
      width: 212px; }
    .button_disabled:nth-child(4) {
      width: 156px;
      height: 46px; }
  .pagination__images {
    align-items: center;
    flex-direction: column;
    justify-content: center; }
  .calendar__image-wrapper {
    flex-direction: column;
    align-items: center; }
  .layout__grid {
    width: 774px; }
    .layout__grid_tablet {
      width: max-content; }
    .layout__grid_mobile {
      width: max-content; }
  .logo__list {
    grid-template-columns: repeat(2, auto); }
    .logo__list-item {
      height: 385px; }
  .header__block-text {
    width: 100%; } }
